import React from 'react';
import SideNavigation from '@amzn/awsui-components-react/polaris/side-navigation';
import classes from './SideBar.module.css';
import ComponentNames from '../../../common/componentNameConstants';
import { ENV_VARIABLES } from '../../../configurations/environment';

const SideBar = ({ activePage, setActivePage }) => {
  return (
    <SideNavigation
      className={classes.SideBar}
      activeHref={activePage}
      header={{ href: ComponentNames.PAGES.chats, text: 'LIVE CHAT' }}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          setActivePage(event.detail.href);
        }
      }}
      items={[
        { type: 'link', text: 'Chats', href: ComponentNames.PAGES.chats },
        {
          type: 'link-group',
          text: 'Reports',
          href: ComponentNames.PAGES.reports,
          items: [
            {
              type: 'link',
              text: 'Operational Agent Report',
              href: ENV_VARIABLES.operationalAgentReportUrl,
              external: true,
            },
            {
              type: 'link',
              text: 'Operational Queue Report',
              href: ENV_VARIABLES.operationalQueueReportUrl,
              external: true,
            },
            {
              type: 'link',
              text: 'Operational Routing Profiles Report',
              href: ENV_VARIABLES.operationalRoutingProfilesReportUrl,
              external: true,
            },
            {
              type: 'link',
              text: 'Historical Agent Report',
              href: ENV_VARIABLES.historicalAgentReportUrl,
              external: true,
            },
          ],
        },
        {
          type: 'link-group',
          text: 'User Management',
          items: [
            {
              type: 'link',
              text: 'Request Access',
              href: ENV_VARIABLES.userManagementTicketUrl,
              external: true,
            },
          ],
        },
        { type: 'divider' },
      ]}
    />
  );
};

export default SideBar;
