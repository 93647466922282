import React, { useEffect } from 'react';
import 'amazon-connect-streams';
import 'amazon-connect-chatjs';
import classes from './CCP.module.css';
import { ENV_VARIABLES } from '../../../../configurations/environment';

const CCP = () => {
  const ccpRef = React.useRef();
  useEffect(() => {
    // eslint-disable-next-line no-undef
    connect.core.initCCP(ccpRef.current, {
      ccpUrl: ENV_VARIABLES.ccpUrl,
      loginUrl: ENV_VARIABLES.loginUrl,
      loginPopup: true, // optional, defaults to `true`
      loginPopupAutoClose: true, // optional, defaults to `false`
      loginOptions: {
        // optional, if provided opens login in new window
        autoClose: true, // optional, defaults to `false`
        height: 600, // optional, defaults to 578
        width: 400, // optional, defaults to 433
        top: 0, // optional, defaults to 0
        left: 0, // optional, defaults to 0
      },
      region: ENV_VARIABLES.region, // REQUIRED for `CHAT`, optional otherwise
      softphone: {
        // optional, defaults below apply if not provided
        allowFramedSoftphone: true, // optional, defaults to false
        disableRingtone: false, // optional, defaults to false
        ringtoneUrl: './ringtone.mp3', // optional, defaults to CCP’s default ringtone if a falsy value is set
      },
      pageOptions: {
        //optional
        enableAudioDeviceSettings: false, //optional, defaults to 'false'
        enablePhoneTypeSettings: true, //optional, defaults to 'true'
      },
      ccpAckTimeout: 3000, //optional, defaults to 3000 (ms)
      ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
      ccpLoadTimeout: 5000, //optional, defaults to 5000 (ms)
    });
  }, []);

  return (
    <div className="ccp">
      <div className={classes.CCP} ref={ccpRef} />
    </div>
  );
};

export default CCP;
