import { useEffect, useState } from 'react';
import AppContainer from './containers/AppContainer/AppContainer';
import { CognitoAuthHelper } from './service/cognitoAuthHelper';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const App = () => {
  const [authToken, setAuthToken] = useState(null);

  // TODO Implement token refresh on user interaction
  useEffect(() => {
    (async () => {
      const cognitoAuthHelper = new CognitoAuthHelper();
      const loginDetails = await cognitoAuthHelper.login();
      setAuthToken(loginDetails.authToken);
    })();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={authToken ? <AppContainer authToken={authToken} /> : null}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
