import axios from 'axios';
import { ENV_VARIABLES } from '../configurations/environment';

const CUSTOMER_PROFILE_API = `${ENV_VARIABLES.customerProfileApi}`;
const CUSTOMER_ACTIVITY_API = `${ENV_VARIABLES.customerActivityApi}`;

export class ApiRepo {
  static async getCrmCustomerRecord(authToken, customerId, customerType) {
    try {
      const response = await axios.get(
        `${CUSTOMER_PROFILE_API}?customerId=${customerId}&customerType=${customerType}`,
        {
          headers: {
            Authorization: authToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data;
    } catch (e) {
      console.error(`Failed to retrieve lead data: ${e}`);
    }
  }

  static async updateCrmCustomerRecord(authToken, customerInfo, agentInfo) {
    try {
      const response = await axios.put(
        CUSTOMER_PROFILE_API,
        JSON.stringify({
          customerInfo: customerInfo.data,
          agentInfo,
        }),
        {
          headers: {
            Authorization: authToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data;
    } catch (e) {
      console.error(`Failed to update lead data: ${e}`);
    }
  }

  static async createCrmCustomerActivity(
    authToken,
    contactActivity,
    campaignHistory,
    agentInfo
  ) {
    try {
      const response = await axios.post(
        CUSTOMER_ACTIVITY_API,
        JSON.stringify({
          contactActivity,
          campaignHistory,
          agentInfo,
        }),
        {
          headers: {
            Authorization: authToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data;
    } catch (e) {
      console.error(`Failed to create customer activity: ${e}`);
    }
  }
}

export default ApiRepo;
