export const onPageClose = () => {
  window.addEventListener('beforeunload', unloadCallback);
  return () => window.removeEventListener('beforeunload', unloadCallback);
};

const unloadCallback = (event) => {
  let currAgent;

  // eslint-disable-next-line no-undef
  connect.agent((agent) => {
    currAgent = agent;
  });

  if (currAgent != null) {
    const states = currAgent.getAgentStates();
    const offlineState = states.filter((state) => state.name === 'Offline')[0];
    // Change agent state
    currAgent.setState(offlineState, {
      success: () => {
        console.log('Set agent state to offline succeeded');
      },
      failure: () => {
        console.log('Set agent state to offline failed');
        event.preventDefault();
        return (event.returnValue =
          'You need to manually set status to offline.');
      },
    });
  }
};
