import React from 'react';
import classes from './Footer.module.css';
import { Icon } from '@amzn/awsui-components-react/polaris';
import { ENV_VARIABLES } from '../../configurations/environment';

const Footer = (props) => {
  return (
    <>
      <footer id="ccp-footer" className={classes.Footer}>
        <div
          className={classes.FeedbackSection}
          onClick={() => {
            window
              .open(
                ENV_VARIABLES.sfdcFeedbackTicketUrl,
                '_blank',
                'noopener,noreferrer'
              )
              .focus();
          }}
        >
          <Icon name="contact" className={classes.FeedbackIcon} />
          <span className={classes.FeedbackText}>Feedback</span>
        </div>
        <span className={classes.Close} onClick={props.removeFooter}>
          &times;
        </span>
        <div className={classes.FooterContentSection}>
          <p className={classes.Contents}>
            © 2008 - 2020, Amazon Web Services, Inc. or its affiliates. All
            rights reserved.
          </p>
          <p className={classes.Contents}>
            <a
              className={classes.Link}
              href="https://aws.amazon.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
          <p className={classes.Contents}>
            <a
              className={classes.Link}
              href="https://aws.amazon.com/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
