import React from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import classes from './GeneralInfo.module.css';

const GeneralInfo = ({ activeContactProfile }) => {
  return (
    <Container
      className={classes.GeneralInfo}
      header={<Header variant="h2">General Information</Header>}
    >
      {activeContactProfile &&
        Object.keys(activeContactProfile).map((key) => (
          <div key={key}>
            {activeContactProfile[key]['name'].split(/(?=[A-Z])/).join(' ')}:{' '}
            {activeContactProfile[key]['value']}
          </div>
        ))}
    </Container>
  );
};

export default GeneralInfo;
