import React, { useState, useEffect } from 'react';
import classes from './AppContainer.module.css';
import Header from '../../components/Header/Header';
import SideBar from '../../components/body/SideBar/SideBar';
import Chats from '../../components/body/Chats/Chats';
import Footer from '../../components/Footer/Footer';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { onPageClose } from '../../service/onPageClose';
import ComponentNames from '../../common/componentNameConstants';
// import Reports from '../../components/body/Reports/Reports';
// import UserManagement from '../../components/body/UserManagement/UserManagement';

const AppContainer = ({ authToken }) => {
  const [activePage, setActivePage] = useState(ComponentNames.PAGES.chats);
  const [isCcpInitialized, setIsCcpInitialized] = useState(false);
  const [agentProfile, setAgentProfile] = useState({});

  // let activePageComponent;
  // if (activePage === ComponentNames.PAGES.chats) {
  //   activePageComponent = null;
  // } else if (activePage === ComponentNames.PAGES.reports) {
  //   activePageComponent = <Reports />;
  // } else if (activePage === ComponentNames.PAGES.userManagement) {
  //   activePageComponent = <UserManagement />;
  // }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    connect.core.onInitialized(() => {
      // eslint-disable-next-line no-undef
      connect.agent((agent) => {
        const { name, username } = agent.getConfiguration();
        setAgentProfile({
          name,
          username,
        });
        setIsCcpInitialized(true);
      });
    });

    return onPageClose();
  }, []);

  return (
    <>
      {!isCcpInitialized && (
        <div className={classes.Loader}>
          <Spinner size="large" />
        </div>
      )}
      <div className={isCcpInitialized ? null : classes.Invisible}>
        <Header agentProfile={agentProfile} />
        <AppLayout
          headerSelector="#ccp-header"
          navigation={
            <SideBar activePage={activePage} setActivePage={setActivePage} />
          }
          content={
            // Uncomment the below code when adding back Report and User Management pages
            // <>
            //   <div
            //     className={
            //       activePage !== ComponentNames.PAGES.chats
            //         ? classes.Invisible
            //         : null
            //     }
            //   >
            <Chats authToken={authToken} agentProfile={agentProfile} />
            //   </div>
            //   {activePageComponent}
            // </>
          }
          toolsHide={true}
          footerSelector="#ccp-footer"
        />
        <Footer />
      </div>
    </>
  );
};

export default AppContainer;
