import React, { useState, useEffect, useRef } from 'react';
import classes from './Chats.module.css';
import CCP from './CCP/CCP';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import CrmData from './CrmData/CrmData';
import ApiRepo from '../../../service/apiRepo';
import ComponentNames from '../../../common/componentNameConstants';
import { Grid, SpaceBetween, Tabs } from '@amzn/awsui-components-react';

const Chats = ({ authToken, agentProfile }) => {
  const [activeTab, setActiveTab] = useState(
    ComponentNames.TABS.customerInfoTab
  );
  const [contactProfileMap, setContactProfileMap] = useState({});
  const [activeContactId, setActiveContactId] = useState(null);
  const [crmCustomerRecordMap, _setCrmCustomerRecordMap] = useState({});

  /**
   * Flag to ensure that we only set up subscription to onViewContact once.
   * onViewContact does not return subscription object for effect cleanup.
   */
  const [isActiveContactSubscribed, setIsActiveContactSubscribed] =
    useState(false);

  const crmCustomerRecordMapRef = useRef(crmCustomerRecordMap);
  const setCrmCustomerRecordMap = (data) => {
    crmCustomerRecordMapRef.current = data;
    _setCrmCustomerRecordMap(data);
  };

  useEffect(() => {
    const updateContactProfileMap = (profileObj) => {
      setContactProfileMap({
        ...contactProfileMap,
        ...profileObj,
      });
    };

    const removeContactProfile = (contactId) => {
      const updatedContactProfileMap = { ...contactProfileMap };
      delete updatedContactProfileMap[contactId];
      setContactProfileMap({
        ...updatedContactProfileMap,
      });
    };

    const removeCrmCustomerRecord = (contactId) => {
      const updatedCrmCustomerRecord = { ...crmCustomerRecordMap };
      delete updatedCrmCustomerRecord[contactId];
      setCrmCustomerRecordMap(updatedCrmCustomerRecord);
    };

    const fetchCrmCustomerRecord = async (contactId, profile) => {
      const crmCustomerRecordObj = {};
      crmCustomerRecordObj[contactId] = {
        isFetchingRecord: true,
      };
      setCrmCustomerRecordMap({
        ...crmCustomerRecordMapRef.current,
        ...crmCustomerRecordObj,
      });

      const crmCustomerRecord = await ApiRepo.getCrmCustomerRecord(
        authToken,
        profile.customerId.value,
        profile.customerType.value
      );

      if (crmCustomerRecord?.data?.data) {
        crmCustomerRecord.data.data.customerId = profile.customerId.value;
        crmCustomerRecord.data.data.customerType = profile.customerType.value;
        crmCustomerRecordObj[contactId] = {
          ...crmCustomerRecord.data,
          inquiryNature: profile.enquiry.value,
          chatNote: '',
          isUpdated: false,
          isFetchingRecord: false,
        };
        setCrmCustomerRecordMap({
          ...crmCustomerRecordMapRef.current,
          ...crmCustomerRecordObj,
        });
      } else {
        crmCustomerRecordObj[contactId] = {
          isFetchingRecord: false,
        };
        setCrmCustomerRecordMap({
          ...crmCustomerRecordMapRef.current,
          ...crmCustomerRecordObj,
        });
        console.error(
          'Something went wrong, crmCustomerRecord or crmCustomerRecord.data or crmCustomerRecord.data.data is null/empty'
        );
      }
    };

    const updateCrmRecord = (currContactId, agentAlias) => {
      const endedCustomerRecord =
        crmCustomerRecordMapRef.current[currContactId];
      return ApiRepo.updateCrmCustomerRecord(authToken, endedCustomerRecord, {
        agentAlias: agentAlias,
      });
    };

    const logActivity = (currContactId, agentAlias) => {
      const endedCustomerRecord =
        crmCustomerRecordMapRef.current[currContactId];
      const campaignHistory = {
        campaignMemberId: endedCustomerRecord.campaignMemberId,
        mrcStatus: endedCustomerRecord.mrcStatus,
      };
      // ToDo: startTime and endTime will be fetched together from transcript
      const startTime = new Date();
      const endTime = new Date();

      const durationInSeconds = Math.round((endTime - startTime) / 1000);
      const contactActivity = {
        contactId: currContactId,
        customerId: endedCustomerRecord.data.customerId,
        customerType: endedCustomerRecord.data.customerType,
        durationInSeconds: durationInSeconds.toString(),
        dueDateString: new Date().toISOString().substr(0, 10),
        notes: endedCustomerRecord.chatNote,
        transcript: '<>',
      };
      return ApiRepo.createCrmCustomerActivity(
        authToken,
        contactActivity,
        campaignHistory,
        {
          agentAlias,
        }
      );
    };
    // eslint-disable-next-line no-undef
    const contactLifecycleSub = connect.contact((contact) => {
      contact.onConnected((contact) => {
        // on chat connected get customer profile
        const profile = contact.getAttributes();
        const contactId = contact.getContactId();
        const profileObj = {};
        profileObj[contactId] = profile;
        updateContactProfileMap(profileObj);
        fetchCrmCustomerRecord(contactId, profile);
      });

      if (agentProfile.username) {
        contact.onDestroy((contact) => {
          const currContactId = contact.contactId;
          if (!crmCustomerRecordMapRef.current[currContactId].saved) {
            console.log('onDestroy, Logging chat activity in SFDC');
            logActivity(currContactId, agentProfile.username)
              .then(() => {
                console.log('>> Logged chat activity in SFDC successfully');
              })
              .catch((e) => {
                console.error('Failed to log chat activity record', e);
              });

            console.log('Updating customer record in SFDC');
            updateCrmRecord(currContactId, agentProfile.username)
              .then(() => {
                console.log('>> Updated customer record in SFDC successfully');
                removeContactProfile(currContactId);
                removeCrmCustomerRecord(currContactId);
              })
              .catch((e) => {
                console.error('Failed to update customer record', e);
              });
            crmCustomerRecordMapRef.current[currContactId].saved = true;
          }
        });
      }
    });

    if (!isActiveContactSubscribed) {
      // eslint-disable-next-line no-undef
      connect.core.onViewContact((e) => {
        setActiveTab(ComponentNames.TABS.customerInfoTab);
        setActiveContactId(e.contactId);
      });
      setIsActiveContactSubscribed(true);
    }

    // Effect cleanup
    return () => {
      if (contactLifecycleSub?.unsubscribe) {
        contactLifecycleSub.unsubscribe();
      }
    };
  }, [
    authToken,
    agentProfile,
    activeContactId,
    contactProfileMap,
    crmCustomerRecordMap,
    isActiveContactSubscribed,
  ]);

  return (
    <>
      <SpaceBetween direction="vertical" size="xxl">
        <Grid
          gridDefinition={[
            { colspan: { default: 12, m: 8, xs: 7 } }, // colspan = [12 / (the number of elements to distribute)]
            { colspan: { default: 12, m: 4, xs: 5 } }, // break points: m -> 1120px, xs -> 688px
          ]}
        >
          <div className={classes.CCPGrid}>
            <CCP />
          </div>
          <div>
            <Grid
              gridDefinition={[
                { colspan: { default: 12 } },
                { colspan: { default: 12 } },
                { colspan: { default: 12 } },
                { colspan: { default: 12 } },
                { colspan: { default: 12 } },
              ]}
            >
              <Tabs
                activeTabId={activeTab}
                onChange={(e) => {
                  setActiveTab(e.detail.activeTabId);
                }}
                tabs={[
                  {
                    label: 'Customer Information',
                    id: ComponentNames.TABS.customerInfoTab,
                    content: (
                      <GeneralInfo
                        activeContactProfile={
                          contactProfileMap[activeContactId]
                        }
                      />
                    ),
                  },
                  {
                    label: 'SFDC Record',
                    id: ComponentNames.TABS.crmDataTab,
                    disabled:
                      !activeContactId ||
                      !crmCustomerRecordMap[activeContactId],
                    content: (
                      <CrmData
                        activeContactId={activeContactId}
                        crmCustomerRecordMap={crmCustomerRecordMap}
                        setCrmCustomerRecordMap={setCrmCustomerRecordMap}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
          </div>
        </Grid>
      </SpaceBetween>
    </>
  );
};

export default Chats;
