class CrmDataOptions {
  static MRC_DISQUALIFIED_STATUSES = [
    {
      label: 'Disqualified - Already Working with Sales',
      value: 'Disqualified - Already Working with Sales',
    },
    {
      label: 'Disqualified - Billing Inquiry',
      value: 'Disqualified - Billing Inquiry',
    },
    {
      label: 'Disqualified - Billing to Other Region',
      value: 'Disqualified - Billing to Other Region',
    },
    {
      label: 'Disqualified - Competitor',
      value: 'Disqualified - Competitor',
    },
    {
      label: 'Disqualified - Invalid Data',
      value: 'Disqualified - Invalid Data',
    },
    {
      label: 'Disqualified - Non-AWS Inquiry',
      value: 'Disqualified - Non-AWS Inquiry',
    },
    {
      label: 'Disqualified - No Need',
      value: 'Disqualified - No Need',
    },
    {
      label: 'Disqualified - No Opportunity',
      value: 'Disqualified - No Opportunity',
    },
    {
      label: 'Disqualified - Personal Interest',
      value: 'Disqualified - Personal Interest',
    },
    {
      label: 'Disqualified - Tech Support Request',
      value: 'Disqualified - Tech Support Request',
    },
    {
      label: 'Disqualified - Unreachable',
      value: 'Disqualified - Unreachable',
    },
    {
      label: 'Disqualified - Use Competitor',
      value: 'Disqualified - Use Competitor',
    },
  ];

  static MRC_QUALIFIED_STATUSES = [
    {
      label: 'Qualified - Passed to Sales',
      value: 'Qualified - Passed to Sales',
    },
    {
      label: 'Qualified - Free Tier',
      value: 'Qualified - Free Tier',
    },
    {
      label: 'Qualified - Self Service',
      value: 'Qualified - Self Service',
    },
    {
      label: 'Qualified - Nurturing',
      value: 'Qualified - Nurturing',
    },
  ];

  static MRC_STATUSES = [
    {
      label: 'Open',
      value: 'Open',
    },
    ...CrmDataOptions.MRC_QUALIFIED_STATUSES,
    ...CrmDataOptions.MRC_DISQUALIFIED_STATUSES,
    {
      label: 'Already Working With Sales',
      value: 'Already Working With Sales',
    },
    {
      label: 'Validation',
      value: 'Validation',
    },
    {
      label: 'Needs Reassignment',
      value: 'Needs Reassignment',
    },
    { label: 'Existing Partner', value: 'Existing Partner' },
    { label: 'New Partner', value: 'New Partner' },
    { label: 'Competitor', value: 'Competitor' },
  ];

  static NATURE_OF_INQUIRY = [
    { label: 'General', value: 'General' },
    { label: 'TroubleShooting', value: 'TroubleShooting' },
    { label: 'Billing', value: 'Billing' },
  ];

  static SUPPORTED_LEAD_INDUSTRY_LIST = [
    {
      label: 'Aerospace',
      value: 'Aerospace',
    },
    {
      label: 'Agriculture',
      value: 'Agriculture',
    },
    {
      label: 'Automotive',
      value: 'Automotive',
    },
    {
      label: 'Computers & Electronics',
      value: 'Computers & Electronics',
    },
    {
      label: 'Computers & Electronics',
      value: 'Computers & Electronics',
    },
    {
      label: 'Education',
      value: 'Education',
    },
    { label: 'Financial Services', value: 'Financial Services' },
    { label: 'Gaming', value: 'Gaming' },
    { label: 'Government', value: 'Government' },
    { label: 'Healthcare', value: 'Healthcare' },
    { label: 'Hospitality', value: 'Hospitality' },
    { label: 'Life Sciences', value: 'Life Sciences' },
    { label: 'Manufacturing', value: 'Manufacturing' },
    { label: 'Marketing & Advertising', value: 'Marketing & Advertising' },
    { label: 'Media & Entertainment', value: 'Media & Entertainment' },
    { label: 'Mining', value: 'Mining' },
    { label: 'Non-Profit Organization', value: 'Non-Profit Organization' },
    { label: 'Oil & Gas', value: 'Oil & Gas' },
    { label: 'Other', value: 'Other' },
    { label: 'Power & Utilities', value: 'Power & Utilities' },
    { label: 'Professional Services', value: 'Professional Services' },
    {
      label: 'Real Estate & Construction',
      value: 'Real Estate & Construction',
    },
    { label: 'Retail', value: 'Retail' },
    { label: 'Software & Internet', value: 'Software & Internet' },
    { label: 'Telecommunications', value: 'Telecommunications' },
    { label: 'Transport & Logistics', value: 'Transport & Logistics' },
    { label: 'Travel', value: 'Travel' },
    { label: 'Wholesale & Distribution', value: 'Wholesale & Distribution' },
    { label: '--None--', value: '--None--' },
  ];

  static SUPPORTED_JOB_ROLE_LIST = [
    {
      label: 'Academic / Researcher',
      value: 'Academic / Researcher',
    },
    {
      label: 'Advisor / Consultant',
      value: 'Advisor / Consultant',
    },
    {
      label: 'Business Executive',
      value: 'Business Executive',
    },
    {
      label: 'Entrepreneur (Founder/Co-Founder)',
      value: 'Entrepreneur (Founder/Co-Founder)',
    },
    {
      label: 'IT Executive',
      value: 'IT Executive',
    },
    {
      label: 'IT Professional or Technical Manager',
      value: 'IT Professional or Technical Manager',
    },
    { label: 'Press / Media Analyst', value: 'Press / Media Analyst' },
    { label: 'Sales / Marketing', value: 'Sales / Marketing' },
    {
      label: 'Solution or Systems Architect',
      value: 'Solution or Systems Architect',
    },
    { label: 'Student', value: 'Student' },
    { label: 'System Administrator', value: 'System Administrator' },
    { label: 'Venture Capitalist', value: 'Venture Capitalist' },
    { label: '--None--', value: '--None--' },
  ];

  static CRM_RECORD_CUSTOMER_TYPE_LEAD = 'Lead';
  static CRM_RECORD_CUSTOMER_TYPE_CONTACT = 'Contact';

  static CRM_RECORD_ACCESS_READ_ONLY = 'ReadOnly';
  static CRM_RECORD_ACCESS_READ_WRITE = 'ReadWrite';

  // Form Field Values
  static CRM_RECORD_MRC_STATUS_FIELD = {
    label: 'MRC Status',
    leadFieldKey: 'mrcStatus',
    contactFieldKey: 'mrcStatus',
  };
  static CRM_RECORD_FIRST_NAME_FIELD = {
    label: 'First Name',
    leadFieldKey: 'firstName',
    contactFieldKey: 'firstName',
  };
  static CRM_RECORD_LAST_NAME_FIELD = {
    label: 'Last Name',
    leadFieldKey: 'lastName',
    contactFieldKey: 'lastName',
  };
  static CRM_RECORD_EMAIL_FIELD = {
    label: 'Email',
    leadFieldKey: 'email',
    contactFieldKey: 'email',
  };
  static CRM_RECORD_TITLE_FIELD = {
    label: 'Title',
    leadFieldKey: 'title',
    contactFieldKey: 'title',
  };
  static CRM_RECORD_PHONE_FIELD = {
    label: 'Phone Number',
    leadFieldKey: 'phone',
    contactFieldKey: 'phone',
  };
  static CRM_RECORD_JOB_ROLE_FIELD = {
    label: 'Job Role',
    leadFieldKey: 'job_Role__c',
    contactFieldKey: 'job_Role__c',
  };
  static CRM_RECORD_COMPANY_FIELD = {
    label: 'Company',
    leadFieldKey: 'company',
    contactFieldKey: null,
  };
  static CRM_RECORD_INDUSTRY_FIELD = {
    label: 'Industry',
    leadFieldKey: 'industry',
    contactFieldKey: null,
  };
  static CRM_RECORD_ACCOUNT_NAME_FIELD = {
    label: 'Account Name',
    leadFieldKey: null,
    contactFieldKey: 'account_Name__c',
  };
  static CRM_RECORD_COUNTRY_FIELD = {
    label: 'Country',
    leadFieldKey: 'country',
    contactFieldKey: 'mailingCountry',
  };
  static CRM_RECORD_POSTAL_CODE_FIELD = {
    label: 'Postal Code',
    leadFieldKey: 'postalCode',
    contactFieldKey: 'mailingPostalCode',
  };
  static CRM_RECORD_CHAT_NOTE_FIELD = {
    label: 'Chat Notes',
    leadFieldKey: 'chatNote',
    contactFieldKey: 'chatNote',
  };
}

export default CrmDataOptions;
