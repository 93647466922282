const stageConfigurationMap = {
  prod: {
    ccpUrl: process.env.REACT_APP_CCP_URL_PROD,
    loginUrl: process.env.REACT_APP_CCP_LOGIN_URL_PROD,
    customerProfileApi: process.env.REACT_APP_CUSTOMER_PROFILE_API_PROD,
    customerActivityApi: process.env.REACT_APP_CUSTOMER_ACTIVITY_API_PROD,
    region: process.env.REACT_APP_REGION,
    operationalAgentReportUrl:
      process.env.REACT_APP_OPERATIONAL_AGENT_REPORT_URL_PROD,
    operationalQueueReportUrl:
      process.env.REACT_APP_OPERATIONAL_QUEUE_REPORT_URL_PROD,
    operationalRoutingProfilesReportUrl:
      process.env.REACT_APP_OPERATIONAL_ROUTING_PROFILES_REPORT_URL_PROD,
    historicalAgentReportUrl:
      process.env.REACT_APP_HISTORICAL_AGENT_REPORT_URL_PROD,
    userManagementTicketUrl: process.env.REACT_APP_USER_MANAGEMENT_TICKET_URL,
    reportBugTicketUrl: process.env.REACT_APP_REPORT_BUG_TICKET_URL,
    sfdcFeedbackTicketUrl: process.env.REACT_APP_SFDC_FEEDBACK_TICKET_URL,
  },
  gamma: {
    ccpUrl: process.env.REACT_APP_CCP_URL_GAMMA,
    loginUrl: process.env.REACT_APP_CCP_LOGIN_URL_GAMMA,
    customerProfileApi: process.env.REACT_APP_CUSTOMER_PROFILE_API_GAMMA,
    customerActivityApi: process.env.REACT_APP_CUSTOMER_ACTIVITY_API_GAMMA,
    region: process.env.REACT_APP_REGION,
    operationalAgentReportUrl:
      process.env.REACT_APP_OPERATIONAL_AGENT_REPORT_URL_GAMMA,
    operationalQueueReportUrl:
      process.env.REACT_APP_OPERATIONAL_QUEUE_REPORT_URL_GAMMA,
    operationalRoutingProfilesReportUrl:
      process.env.REACT_APP_OPERATIONAL_ROUTING_PROFILES_REPORT_URL_GAMMA,
    historicalAgentReportUrl:
      process.env.REACT_APP_HISTORICAL_AGENT_REPORT_URL_GAMMA,
    userManagementTicketUrl: process.env.REACT_APP_USER_MANAGEMENT_TICKET_URL,
    reportBugTicketUrl: process.env.REACT_APP_REPORT_BUG_TICKET_URL,
    sfdcFeedbackTicketUrl: process.env.REACT_APP_SFDC_FEEDBACK_TICKET_URL,
  },
  beta: {
    ccpUrl: process.env.REACT_APP_CCP_URL_BETA,
    loginUrl: process.env.REACT_APP_CCP_LOGIN_URL_BETA,
    customerProfileApi: process.env.REACT_APP_CUSTOMER_PROFILE_API_BETA,
    customerActivityApi: process.env.REACT_APP_CUSTOMER_ACTIVITY_API_BETA,
    region: process.env.REACT_APP_REGION,
    operationalAgentReportUrl:
      process.env.REACT_APP_OPERATIONAL_AGENT_REPORT_URL_BETA,
    operationalQueueReportUrl:
      process.env.REACT_APP_OPERATIONAL_QUEUE_REPORT_URL_BETA,
    operationalRoutingProfilesReportUrl:
      process.env.REACT_APP_OPERATIONAL_ROUTING_PROFILES_REPORT_URL_BETA,
    historicalAgentReportUrl:
      process.env.REACT_APP_HISTORICAL_AGENT_REPORT_URL_BETA,
    userManagementTicketUrl: process.env.REACT_APP_USER_MANAGEMENT_TICKET_URL,
    reportBugTicketUrl: process.env.REACT_APP_REPORT_BUG_TICKET_URL,
    sfdcFeedbackTicketUrl: process.env.REACT_APP_SFDC_FEEDBACK_TICKET_URL,
  },
  alpha: {
    ccpUrl: process.env.REACT_APP_CCP_URL_ALPHA,
    loginUrl: process.env.REACT_APP_CCP_LOGIN_URL_ALPHA,
    customerProfileApi: process.env.REACT_APP_CUSTOMER_PROFILE_API_ALPHA,
    customerActivityApi: process.env.REACT_APP_CUSTOMER_ACTIVITY_API_ALPHA,
    region: process.env.REACT_APP_REGION,
    operationalAgentReportUrl:
      process.env.REACT_APP_OPERATIONAL_AGENT_REPORT_URL_BETA,
    operationalQueueReportUrl:
      process.env.REACT_APP_OPERATIONAL_QUEUE_REPORT_URL_BETA,
    operationalRoutingProfilesReportUrl:
      process.env.REACT_APP_OPERATIONAL_ROUTING_PROFILES_REPORT_URL_BETA,
    historicalAgentReportUrl:
      process.env.REACT_APP_HISTORICAL_AGENT_REPORT_URL_BETA,
    userManagementTicketUrl: process.env.REACT_APP_USER_MANAGEMENT_TICKET_URL,
    reportBugTicketUrl: process.env.REACT_APP_REPORT_BUG_TICKET_URL,
    sfdcFeedbackTicketUrl: process.env.REACT_APP_SFDC_FEEDBACK_TICKET_URL,
  },
};

const getStage = () => {
  const hostname = window.location.hostname;
  const stageList = ['alpha', 'beta', 'gamma', 'prod'];

  if (hostname === 'localhost') return process.env.REACT_APP_STAGE || 'beta';

  const stageName = hostname.split('.').slice(2, 3).pop();
  return stageList.includes(stageName) ? stageName : 'beta';
};

const stage = getStage();

console.log('stage: ', stage);

export const ENV_VARIABLES = {
  ...stageConfigurationMap[stage],
};

const cognitoStageConfigs = {
  alpha: {
    // The domain name in Cognito "App integration > Domain name", without https://
    AppWebDomain:
      'mrc-sunrise-agent-ui-oidc-alpha-1.auth.us-east-1.amazoncognito.com',
    // the client ID from Cognito "General settings > App clients" page
    ClientId: '2r7uo076j5a6lf7kj8ck4kkhn8',
    // exactly same as the callback URLs in Cognito "App integration > App client settings" page,
    // including the trailing slash as well
    RedirectUriSignIn: `https://${process.env.REACT_APP_AGENT_DOMAIN_ALPHA}/`,
    RedirectUriSignOut: `https://${process.env.REACT_APP_AGENT_DOMAIN_ALPHA}/`,
    // These values are hard-coded
    TokenScopesArray: ['openid'],
    // the user pool from Cognito "General settings" page
    UserPoolId: 'us-east-1_Qmy1nL60V',
  },
  beta: {
    // The domain name in Cognito "App integration > Domain name", without https://
    AppWebDomain:
      'mrc-sunrise-agent-ui-oidc-beta.auth.us-east-1.amazoncognito.com',
    // the client ID from Cognito "General settings > App clients" page
    ClientId: '7qhsh5ukli8669brir24r1hp55',
    // exactly same as the callback URLs in Cognito "App integration > App client settings" page,
    // including the trailing slash as well
    RedirectUriSignIn: `https://${process.env.REACT_APP_AGENT_DOMAIN_BETA}/`,
    RedirectUriSignOut: `https://${process.env.REACT_APP_AGENT_DOMAIN_BETA}/`,
    // These values are hard-coded
    TokenScopesArray: ['openid'],
    // the user pool from Cognito "General settings" page
    UserPoolId: 'us-east-1_mv0ZEoTMx',
  },
  gamma: {
    // The domain name in Cognito "App integration > Domain name", without https://
    AppWebDomain:
      'mrc-sunrise-agent-ui-oidc-gamma.auth.us-east-1.amazoncognito.com',
    // the client ID from Cognito "General settings > App clients" page
    ClientId: '4bdaf7nrphdpfmrtkbtli8cd82',
    // exactly same as the callback URLs in Cognito "App integration > App client settings" page,
    // including the trailing slash as well
    RedirectUriSignIn: `https://${process.env.REACT_APP_AGENT_DOMAIN_GAMMA}/`,
    RedirectUriSignOut: `https://${process.env.REACT_APP_AGENT_DOMAIN_GAMMA}/`,
    // These values are hard-coded
    TokenScopesArray: ['openid'],
    // the user pool from Cognito "General settings" page
    UserPoolId: 'us-east-1_bcfCQLtpZ',
  },
  prod: {
    // The domain name in Cognito "App integration > Domain name", without https://
    AppWebDomain:
      'mrc-sunrise-agent-ui-oidc-prod.auth.us-east-1.amazoncognito.com',
    // the client ID from Cognito "General settings > App clients" page
    ClientId: '3vr2hltilncdelj8s75fsggvc3',
    // exactly same as the callback URLs in Cognito "App integration > App client settings" page,
    // including the trailing slash as well
    RedirectUriSignIn: `https://${process.env.REACT_APP_AGENT_DOMAIN_PROD}/`,
    RedirectUriSignOut: `https://${process.env.REACT_APP_AGENT_DOMAIN_PROD}/`,
    // These values are hard-coded
    TokenScopesArray: ['openid'],
    // the user pool from Cognito "General settings" page
    UserPoolId: 'us-east-1_moUfC0bjm',
  },
};

const getCognitoConfigByStage = () => {
  const domainName = window.location.hostname;
  const stageConfig = cognitoStageConfigs[stage];

  if (domainName === 'localhost') {
    stageConfig.RedirectUriSignIn = 'http://localhost:3000/';
    stageConfig.RedirectUriSignOut = 'http://localhost:3000/';
  }

  return stageConfig;
};

export const COGNITO_CONFIG = getCognitoConfigByStage();
