import { CognitoAuth } from 'amazon-cognito-auth-js';
import { COGNITO_CONFIG } from '../configurations/environment';

export class CognitoAuthHelper {
  constructor() {
    this.auth = new CognitoAuth(COGNITO_CONFIG);
  }

  async login() {
    return new Promise((resolve) => {
      // OAuth2 Authorization Code Grant flow is needed for browser based auth since we do not
      // want to store cognito secrets on our end
      this.auth.useCodeGrantFlow();

      // Register callback functions
      this.auth.userhandler = {
        onFailure: (err) => {
          console.log('Login failed with err: ' + JSON.stringify(err));
          this.removeQueryFromLocation();
          this.handleUserAuthFailure(this.auth);
        },
        onSuccess: (result) => {
          console.log('Login succeeded');
          this.removeQueryFromLocation();
          this.handleUserAuthSuccess(result, resolve);
        },
      };

      const href = window.location.href;

      if (!sessionStorage.getItem('pathName')) {
        sessionStorage.setItem('pathName', href);
      }

      const session = this.auth.getSignInUserSession();

      if (session.isValid()) {
        console.log('Session is valid');
        this.handleUserAuthSuccess(session, resolve);
      } else if (href.indexOf('?') > 0) {
        // This is required because Cognito needs to get the authentication result from the query string
        // The parsing is done asynchronously, and the result will be passed to the `auth.userHandler`.
        console.log('Parsing session');
        this.auth.parseCognitoWebResponse(href);
      } else {
        // Cognito SDK will handle session refresh / authentication.
        console.log('Session refresh');
        // This is required because without this users have to refresh the page twice to clear cached tokens
        this.auth.clearCachedTokensScopes();
        this.auth.getSession();
      }
    });
  }

  handleUserAuthSuccess(authResult, resolve) {
    const sessionToken = authResult.getIdToken();
    this.userFields = sessionToken.decodePayload();
    this.authToken = sessionToken.getJwtToken();

    if (sessionStorage.getItem('pathName')) {
      const href = sessionStorage.getItem('pathName');
      sessionStorage.removeItem('pathName');
      if (window.location.href !== href) {
        window.location.replace(href);
      }
    }

    resolve({ authToken: this.authToken, userFields: this.userFields });
  }

  handleUserAuthFailure(auth) {
    this.userFields = auth.getSignInUserSession().getIdToken().decodePayload();
    this.authToken = {};
  }

  removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    if (window.history.length) {
      const newHref = window.location.href.split('?')[0];
      window.history.replaceState(undefined, 'Your App Title', newHref);
    }
  }
}
