import * as React from 'react';
import { FormField, Textarea } from '@amzn/awsui-components-react';

const FormExpandable = ({
  label,
  description,
  placeholder,
  dataObj,
  objHandler,
  selectedField,
}) => {
  return (
    <FormField label={label} description={description} stretch={true}>
      <Textarea
        onChange={({ detail }) => objHandler(selectedField, detail.value)}
        value={dataObj[selectedField]}
        placeholder={placeholder}
      />
    </FormField>
  );
};

export default FormExpandable;
