import * as React from 'react';
import { FormField, Select } from '@amzn/awsui-components-react';

const FormDropdown = ({
  label,
  description,
  options,
  dataObj,
  objHandler,
  selectedField,
  disabled,
}) => {
  return (
    <div>
      <FormField label={label} description={description} stretch={true}>
        <Select
          selectedOption={{
            label: dataObj[selectedField],
            value: dataObj[selectedField],
          }}
          onChange={({ detail }) =>
            objHandler(selectedField, detail.selectedOption.value)
          }
          options={options}
          selectedAriaLabel="Selected"
          placeholder="Choose an option"
          disabled={disabled}
        />
      </FormField>
    </div>
  );
};

export default FormDropdown;
