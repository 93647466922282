import React from 'react';
import classes from './Header.module.css';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';

const Header = ({ agentProfile }) => {
  return (
    <TopNavigation
      className={classes.Header}
      id="ccp-header"
      identity={{
        href: '#',
        title: 'MRC CHAT 2.0',
        // logo: {
        //   src: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSIzMXB4IiB2aWV3Qm94PSIwIDAgNDMgMzEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxyZWN0IGZpbGw9IiMyMzJmM2UiIHN0cm9rZT0iI2Q1ZGJkYiIgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0MiIgaGVpZ2h0PSIzMCIgcng9IjIiPjwvcmVjdD4KICAgICAgICA8dGV4dCBmb250LWZhbWlseT0iQW1hem9uRW1iZXItUmVndWxhciwgQW1hem9uIEVtYmVyIiBmb250LXNpemU9IjEyIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHRzcGFuIHg9IjkiIHk9IjE5Ij5Mb2dvPC90c3Bhbj4KICAgICAgICA8L3RleHQ+CiAgICA8L2c+Cjwvc3ZnPgo=',
        //   alt: 'Service',
        // },
      }}
      utilities={[
        {
          type: 'menu-dropdown',
          text: agentProfile.name,
          description: `${agentProfile.username}@amazon.com`,
          iconUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?login=${agentProfile.username}`,
          iconAlt: 'user-badge',
          items: [{ id: 'signout', text: 'Sign out' }],
        },
      ]}
      i18nStrings={{
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
      }}
    />
  );
};

export default Header;
