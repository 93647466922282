import React from 'react';
import {
  Container,
  Header,
  SpaceBetween,
  Link,
  Spinner,
} from '@amzn/awsui-components-react';
import FormDropdown from '../../../common/FormDropdown/FormDropdown';
import Form from '../../../common/Form/Form';
import FormExpandable from '../../../common/FormExpandable/FormExpandable';
import classes from './CrmData.module.css';
import CrmDataOptions from '../../../../common/crmDataConstants';
import { ENV_VARIABLES } from '../../../../configurations/environment';

const CrmData = ({
  activeContactId,
  crmCustomerRecordMap,
  setCrmCustomerRecordMap,
}) => {
  const crmCustomerRecord = crmCustomerRecordMap[activeContactId];

  const updateCrmCustomerRecord = (key, value) => {
    const updatedCrmCustomerRecordMap = { ...crmCustomerRecordMap };
    if (
      Object.keys(updatedCrmCustomerRecordMap[activeContactId].data).includes(
        key
      )
    ) {
      updatedCrmCustomerRecordMap[activeContactId].data[key] = value;
    } else {
      updatedCrmCustomerRecordMap[activeContactId][key] = value;
    }

    updatedCrmCustomerRecordMap[activeContactId].isUpdated = true;
    setCrmCustomerRecordMap(updatedCrmCustomerRecordMap);
  };

  const loadingView = (
    <Container>
      <div className={classes.SpinnerContainer}>
        <Spinner size="large" />
      </div>
    </Container>
  );

  const fallbackView = (
    <div>
      No SFDC Record found. Please submit a ticket{' '}
      <a
        href={ENV_VARIABLES.reportBugTicketUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      .
    </div>
  );

  if (crmCustomerRecord.isFetchingRecord) {
    return loadingView;
  }

  const getFieldKeyForCurrentRecord = (field) => {
    if (!crmCustomerRecord?.data?.customerType) {
      return null;
    }

    return crmCustomerRecord.data.customerType ===
      CrmDataOptions.CRM_RECORD_CUSTOMER_TYPE_CONTACT
      ? field.contactFieldKey
      : field.leadFieldKey;
  };

  const isFieldWritableForCurrentRecord = (field) => {
    const fieldKey = getFieldKeyForCurrentRecord(field);

    if (!fieldKey) {
      return false;
    }

    return (
      crmCustomerRecord._access[fieldKey] ===
      CrmDataOptions.CRM_RECORD_ACCESS_READ_WRITE
    );
  };

  return !crmCustomerRecord.data ? (
    fallbackView
  ) : (
    <Container
      header={
        <Header variant="h2">
          Record Details
          <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={crmCustomerRecord.sfdcCustomerUrl}
            className={classes.CrmRecordUrl}
          >
            SFDC RECORD PAGE
          </Link>
        </Header>
      }
    >
      <SpaceBetween size="s">
        <FormDropdown
          label={CrmDataOptions.CRM_RECORD_MRC_STATUS_FIELD.label}
          options={CrmDataOptions.MRC_STATUSES}
          dataObj={crmCustomerRecord}
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_MRC_STATUS_FIELD
          )}
        />
        <Form
          label={CrmDataOptions.CRM_RECORD_FIRST_NAME_FIELD.label}
          dataObj={crmCustomerRecord.data}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_FIRST_NAME_FIELD
            )
          }
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_FIRST_NAME_FIELD
          )}
        />
        <Form
          label={CrmDataOptions.CRM_RECORD_LAST_NAME_FIELD.label}
          dataObj={crmCustomerRecord.data}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_LAST_NAME_FIELD
            )
          }
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_LAST_NAME_FIELD
          )}
        />
        <Form
          label={CrmDataOptions.CRM_RECORD_EMAIL_FIELD.label}
          dataObj={crmCustomerRecord.data}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_EMAIL_FIELD
            )
          }
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_EMAIL_FIELD
          )}
        />
        <Form
          label={CrmDataOptions.CRM_RECORD_TITLE_FIELD.label}
          dataObj={crmCustomerRecord.data}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_TITLE_FIELD
            )
          }
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_TITLE_FIELD
          )}
        />
        <Form
          label={CrmDataOptions.CRM_RECORD_PHONE_FIELD.label}
          dataObj={crmCustomerRecord.data}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_PHONE_FIELD
            )
          }
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_PHONE_FIELD
          )}
        />
        <FormDropdown
          label={CrmDataOptions.CRM_RECORD_JOB_ROLE_FIELD.label}
          options={CrmDataOptions.SUPPORTED_JOB_ROLE_LIST}
          dataObj={crmCustomerRecord.data}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_JOB_ROLE_FIELD
            )
          }
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_JOB_ROLE_FIELD
          )}
        />
        <Form
          label={CrmDataOptions.CRM_RECORD_COMPANY_FIELD.label}
          dataObj={crmCustomerRecord.data}
          objHandler={updateCrmCustomerRecord}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_COMPANY_FIELD
            )
          }
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_COMPANY_FIELD
          )}
          isEmptyInputInvalid={true}
        />
        <FormDropdown
          label={CrmDataOptions.CRM_RECORD_INDUSTRY_FIELD.label}
          options={CrmDataOptions.SUPPORTED_LEAD_INDUSTRY_LIST}
          dataObj={crmCustomerRecord.data}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_INDUSTRY_FIELD
            )
          }
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_INDUSTRY_FIELD
          )}
        />
        <Form
          label={CrmDataOptions.CRM_RECORD_ACCOUNT_NAME_FIELD.label}
          dataObj={crmCustomerRecord.data}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_ACCOUNT_NAME_FIELD
            )
          }
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_ACCOUNT_NAME_FIELD
          )}
        />
        <Form
          label={CrmDataOptions.CRM_RECORD_COUNTRY_FIELD.label}
          dataObj={crmCustomerRecord.data}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_COUNTRY_FIELD
            )
          }
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_COUNTRY_FIELD
          )}
        />
        <Form
          label={CrmDataOptions.CRM_RECORD_POSTAL_CODE_FIELD.label}
          dataObj={crmCustomerRecord.data}
          disabled={
            !isFieldWritableForCurrentRecord(
              CrmDataOptions.CRM_RECORD_POSTAL_CODE_FIELD
            )
          }
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_POSTAL_CODE_FIELD
          )}
        />
        <FormExpandable
          label={CrmDataOptions.CRM_RECORD_CHAT_NOTE_FIELD.label}
          placeholder="Add your chat notes / highlights here"
          dataObj={crmCustomerRecord}
          objHandler={updateCrmCustomerRecord}
          selectedField={getFieldKeyForCurrentRecord(
            CrmDataOptions.CRM_RECORD_CHAT_NOTE_FIELD
          )}
        />
      </SpaceBetween>
      {/*<Button*/}
      {/*  variant="primary"*/}
      {/*  className={classes.SubmitButton}*/}
      {/*  disabled={!crmCustomerRecord.isUpdated}*/}
      {/*  onClick={() => updateSFDCRecord()}*/}
      {/*>*/}
      {/*  Save*/}
      {/*</Button>*/}
    </Container>
  );
};

export default CrmData;
