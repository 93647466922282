class ComponentNames {
  static PAGES = {
    chats: '#/chats',
    reports: '#/reports',
    userManagement: '#/userManagement',
  };

  static TABS = {
    customerInfoTab: 'customerInfoTab',
    crmDataTab: 'crmDataTab',
  };
}

export default ComponentNames;
