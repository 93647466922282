import * as React from 'react';
import { FormField, Input } from '@amzn/awsui-components-react';

const Form = ({
  label,
  description,
  dataObj,
  objHandler,
  selectedField,
  disabled = false,
  isEmptyInputInvalid = false,
}) => {
  const [errorText, setErrorText] = React.useState('');
  return (
    <FormField
      label={label}
      description={description}
      stretch={true}
      errorText={errorText}
    >
      <Input
        value={dataObj[selectedField]}
        onChange={({ detail }) => {
          let currentErrorText = null;

          if (isEmptyInputInvalid && !detail.value) {
            currentErrorText = `${label} field cannot be empty.`;
          }

          if (errorText !== currentErrorText) {
            setErrorText(currentErrorText);
          }

          objHandler(selectedField, detail.value);
        }}
        disabled={disabled}
      />
    </FormField>
  );
};

export default Form;
